'use strict';

var base = require('../product/base');

var customQuantityInputControls = $('.custom-quantity-selector__control');
var isPPP = !!$('.product-detail').length;

/**
 * Hide all quantity errors
 */
function hideAllQuantityErrors() {
    $('.quantity-validation-error').hide();
}

/**
 * Show a maximum quantity exceeded error
 * @param {jquery} quantitySelector - The quantity select to update
 * @param {number} maxPossibleValue - The maximum theorical quantity
 */
function showMaxError(quantitySelector, maxPossibleValue) {
    var minError = $(quantitySelector.parents().get(0))?.siblings('.minimum-quantity-validation-error');
    var maxError = $(quantitySelector.parents().get(0))?.siblings('.maximum-quantity-validation-error');
    maxError?.find('.content-asset .maximum-quantity-validation-error__amount')?.html(maxPossibleValue);
    maxError.show();
    minError.hide();
}

/**
 * Show a minimum quantity exceeded error
 * @param {jquery} quantitySelector - The quantity select to update
 * @param {number} minPossibleValue - The maximum theorical quantity
 */
function showMinError(quantitySelector, minPossibleValue) {
    var minError = $(quantitySelector.parents().get(0))?.siblings('.minimum-quantity-validation-error');
    var maxError = $(quantitySelector.parents().get(0))?.siblings('.maximum-quantity-validation-error');
    minError?.find('.content-asset .minimum-quantity-validation-error__amount')?.html(minPossibleValue);
    minError.show();
    maxError.hide();
}

/**
 * Update the custom quantity selector plus and minus buttons state
 * @param {jquery} quantitySelector - The quantity select to update
 * @param {number} minPossibleValue - The minimum theorical quantity
 * @param {number} maxPossibleValue - The maximum theorical quantity
 */
function updateCustomQuantitySelectorControls(quantitySelector, minPossibleValue, maxPossibleValue) {
    // If we only should have one possible value, force the input and buttons to be disabled
    if (quantitySelector && minPossibleValue >= maxPossibleValue) {
        quantitySelector
            .siblings('.custom-quantity-selector__control, .custom-quantity-selector__input')
            .attr('disabled', 'disabled');
    } else {
        var currentQuantityControls = quantitySelector
            ? quantitySelector.siblings('.custom-quantity-selector__control')
            : customQuantityInputControls;
        currentQuantityControls.each(function () {
            var currentQuantitySelector = quantitySelector || $(this).siblings('.custom-quantity-selector__select');
            if (currentQuantitySelector.find('option').length) {
                var type = $(this).attr('data-type');
                var minIndex = minPossibleValue
                    ? currentQuantitySelector.find(`option[value="${minPossibleValue}"]`)?.index()
                    : 0;
                var maxIndex = maxPossibleValue
                    ? currentQuantitySelector.find(`option[value="${maxPossibleValue}"]`)?.index()
                    : currentQuantitySelector.find('option').length - 1;
                var currentIndex = currentQuantitySelector.prop('selectedIndex');
                if ((type === 'minus' && currentIndex > minIndex) || (type === 'plus' && currentIndex < maxIndex)) {
                    $(this).removeAttr('disabled');
                } else {
                    $(this).attr('disabled', 'disabled');
                }
            }
        });
    }
}

/**
 * Triggers quantity selector change to correctly update quanitites and totals
 * @param {jquery} quantitySelector - The quantity select to update
 */
function triggerSelectorUpdate(quantitySelector) {
    var elementToTriggerUpdate;
    if (!quantitySelector?.length) {
        elementToTriggerUpdate = base.getQuantitySelector();
    } else {
        elementToTriggerUpdate = quantitySelector;
    }
    if (elementToTriggerUpdate?.length) {
        elementToTriggerUpdate.trigger('change');
    }
}

/**
 * Change the quantity selector value
 * @param {jquery} quantitySelector - The quantity select to update
 * @param {Object} value - The value to set
 * @param {boolean} avoidUpdateQuantitySelector - Avoids triggering the change event which will cause an infinite loop
 */
function changeSelectValue(quantitySelector, value, avoidUpdateQuantitySelector) {
    var currentOptions = quantitySelector.find('option');
    if (currentOptions) {
        // If present, use the theoretical max/min values defined for the current purchase type
        // (only needed for the cart page quantity selectors)
        var minPossibleValue =
            parseInt(quantitySelector.data('theorical-minimum'), 10) || parseInt(currentOptions.first().val(), 10);
        var maxPossibleValue =
            parseInt(quantitySelector.data('theorical-maximum'), 10) || parseInt(currentOptions.last().val(), 10);
        var valueToSet;
        // If the value entered is invalid, set it to the min/max value
        if (value > maxPossibleValue) {
            valueToSet = maxPossibleValue;
            showMaxError(quantitySelector, maxPossibleValue);
        } else if (value >= minPossibleValue && quantitySelector.find(`option[value="${value}"]`).length) {
            valueToSet = value;
            if (!avoidUpdateQuantitySelector) {
                hideAllQuantityErrors();
            }
        } else {
            valueToSet = minPossibleValue;
            showMinError(quantitySelector, minPossibleValue);
        }
        quantitySelector.val(valueToSet);
        quantitySelector
            .find('option')
            .removeAttr('selected')
            .filter(function () {
                return parseInt(this.value, 10) === valueToSet;
            })
            .attr('selected', 'true');
        if (!avoidUpdateQuantitySelector) {
            triggerSelectorUpdate(quantitySelector);
        }
        updateCustomQuantitySelectorControls(quantitySelector, minPossibleValue, maxPossibleValue);
    }
}

/**
 * Updates the quantity input and controls' status
 * @param {jquery} quantitySelector - The quantity select to update
 */
function updateQuantityInputs(quantitySelector) {
    var quantityInput = quantitySelector.siblings('.custom-quantity-selector__input');
    quantityInput.val(quantitySelector.val());
    if (quantitySelector.find('option').length > 1) {
        quantityInput.removeAttr('disabled');
    } else {
        quantityInput.attr('disabled', 'disabled');
    }
    updateCustomQuantitySelectorControls(quantitySelector);
}

module.exports = function () {
    // If we are in the PPP, update the product status on load
    if (isPPP) {
        triggerSelectorUpdate();
    }
    updateCustomQuantitySelectorControls();

    customQuantityInputControls.on('click', function () {
        var type = $(this).attr('data-type');
        var quantitySelector = $(this).siblings('.custom-quantity-selector__select');
        var maxIndex = quantitySelector.find('option').length - 1;
        var currentIndex = quantitySelector.prop('selectedIndex');
        var valueToSet;
        if (maxIndex) {
            if (type === 'minus' && currentIndex > 0) {
                valueToSet = parseInt(quantitySelector.find('option').eq(--currentIndex).val(), 10);
                changeSelectValue(quantitySelector, valueToSet);
            } else if (type === 'plus' && currentIndex < maxIndex) {
                valueToSet = parseInt(quantitySelector.find('option').eq(++currentIndex).val(), 10);
                changeSelectValue(quantitySelector, valueToSet);
            }
        }
    });
    $('.custom-quantity-selector__select').on('change product:updateQuantitySelectors', function (e, hideAllErrors) {
        if (hideAllErrors) {
            hideAllQuantityErrors();
        }
        updateQuantityInputs($(this));
    });
    $('.custom-quantity-selector__input').on('change', function (e, avoidUpdateQuantitySelector) {
        var quantitySelector = $(this).siblings('.custom-quantity-selector__select');
        changeSelectValue(quantitySelector, parseInt($(this).val(), 10), avoidUpdateQuantitySelector);
    });
    $('body').on('cart:update product:afterAddToCart', function () {
        // If we are in the PPP, always hide the errors after adding to the cart/updating quanities
        if (isPPP) {
            hideAllQuantityErrors();
        }
    });
    $('.custom-quantity-selector__select').on('product:showMaxError', function (e, max) {
        showMaxError($(this), max);
    });
};
